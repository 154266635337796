<template>
<main>
  <Head>
    <title>Senaste nyheter - Ängelholms Ryttarförening</title>
    <meta name="description" content="Senste nyheterna med information från föreningen" />
  </Head>
  <template v-if="list&&postsData">
    <ul class="breadcrumbs">
        <li>
            <router-link to="/">Startsida</router-link>
        </li>
        <li>
            Nyheter
        </li>
    </ul>
    <PostsList v-if="list&&postsData" :initialNews="postsData.posts" :totalPostCount="postsData.totalPostCount" />
  </template>
  <template v-else-if="post">
    <img v-if="post.Slide" :src="'/slides/' + post.Slide.ImageUrl" :alt="post.Slide.Title" />
    <ul class="breadcrumbs">
        <li>
            <router-link to="/">Startsida</router-link>
        </li>
        <li>
            <router-link to="/nyheter">Nyheter</router-link>
        </li>
        <li>
            {{post.Title}}
        </li>
    </ul>
    <article>
      <h1>{{ post.Title}}</h1>
      <small>Publicerat {{post.CreatedDate}} av {{post.DisplayName}}</small>
      <div v-html="post.Content"></div>
    </article>
  </template>
</main>
</template>
<script>
import PostsList from './subcomponents/PostsList.vue'
export default {
  components: {
    PostsList
  },
  data () {
    return {
      postsData: [],
      post: null,
      list: true
    }
  },
  beforeRouteEnter (to, _from, next) {
    next((vm) => vm.getPosts(to))
  },
  beforeRouteUpdate (to, _from, next) {
    this.getPosts(to)
    next()
  },
  methods: {
    getPosts (to) {
      let url = '/api/post'
      if (to.params.post) {
        url += '?postId=' + to.params.post.split('-').pop()
        fetch(url)
          .then(response => response.json())
          .then(data => { this.post = data })
        this.list = false
      } else {
        this.list = true
        fetch(url)
          .then(response => response.json())
          .then(data => { this.postsData = data })
      }
    }
  }
}
</script>
