<template>
  <Head v-if="page">
    <title>{{page.Title}} - Ängelholms Ryttarförening</title>
    <meta name="description" :content="page.Seo" />
  </Head>
  <img v-if="page&&page.Slide" :src="'/slides/' + page.Slide.ImageUrl" :alt="page.Slide.Title" style="width:100%"/>
  <main v-if="page">
    <ul v-if="page.Breadcrumb" class="breadcrumbs">
        <li v-for="crumb in breadCrumbs" :key="crumb">
            <router-link v-if="crumb.url" :to="crumb.url">{{crumb.title}}</router-link>
            <template v-else>{{crumb.title}}</template>
        </li>
    </ul>
    <article :class="{sponsors: sponsors}">
      <h1>{{page.Title}}</h1>
      <div v-html="page.Content"></div>
      <div v-for="sponsor in sponsors" :key="sponsor" class="sponsors__item">
        <a v-if="sponsor.Url" :href="sponsor.Url" :title="sponsor.Title" rel="noopener" target="_blank">
          <img :alt="sponsor.Title" :key="sponsor" :src="'/sponsors/' + sponsor.ImageUrl" />
        </a>
        <img v-else :alt="sponsor.Title" :key="sponsor" :src="'/sponsors/' + sponsor.ImageUrl" />
      </div>
    </article>
  </main>
</template>
<script>
export default {
  data () {
    return {
      page: null,
      sponsors: null,
      error: false
    }
  },
  beforeRouteEnter (to, _from, next) {
    next((vm) => vm.getPage(to))
  },
  beforeRouteUpdate (to, _from, next) {
    this.getPage(to)
    next()
  },
  methods: {
    getPage (to) {
      this.sponsors = null
      fetch('/api/page' + to.path)
        .then(response => response.json())
        .then(data => { this.page = data })
        .catch(() => {
          this.page = {
            Title: 'Oops! Sidan kunde inte hittas (404)',
            Content: 'Det kan bero på att du skrivit en felaktig adress eller att sidan har tagits bort.',
            Breadcrumb: 'Startsida/Sidan kunde inte hittas (404)',
            Url: ''
          }
        })
      if (to.path === '/sponsorer') {
        fetch('/api/sponsors')
          .then(response => response.json())
          .then(data => { this.sponsors = data })
      }
    }
  },
  computed: {
    breadCrumbs () {
      if (!this.page) { return null }
      const titles = this.page.Breadcrumb.split('/')
      const urls = this.page.Url.split('/')
      urls.unshift('')
      const arr = []
      for (let i = 0; i < urls.length; i++) {
        arr.push({ url: i === urls.length - 1 ? null : '/' + urls[i], title: titles[i] })
      }
      return arr
    }
  }
}
</script>
<style lang="scss" scoped>
.sponsors {
  text-align: center;
  &__item {
    img {
      width:100%;
      max-width:300px;
      margin-bottom: 1rem;
    }
  }
}
</style>
