<template>
  <Head>
    <title>Hitta hit - Ängelholms Ryttarförening</title>
    <meta name="description" content="Vägbeskrivning till Ängelholms Ryttarförening" />
  </Head>
    <main>
        <ul class="breadcrumbs">
            <li>
                <router-link to="/">Startsida</router-link>
            </li>
            <li>
               <router-link to="/foreningen">Föreningen</router-link>
            </li>
            <li>
                Hitta hit
            </li>
        </ul>
        <h1>Hitta hit</h1>
        <div class="directions">
            <div>
                <b>E6:an norrifrån</b><br/>
                - Kör av avfart Höja<br/>
                - Tag vänster<br/>
                - Kör ca 470 m<br/>
                - Tag vänster in på en grusväg (se Ridhus skylt)<br/>
                - Följ grusvägen, se ridhusen på vänster sida<br/>
            </div>
            <div>
                <b>E6:an söderifrån</b><br/>
                - Kör av avfart Höja<br/>
                - Tag höger<br/>
                - Kör ca 250 m<br/>
                - Tag vänster in på en grusväg (se Ridhus skylt)<br/>
                - Följ grusvägen, se ridhusen på vänster sida
            </div>
        </div>
        <div id="map"></div>
    </main>
</template>
<script>
import { getCurrentInstance } from '@vue/runtime-core'
export default {
  setup () {
    const mapsKey = 'AIzaSyBMIAU6vWekRj2bfFjCXOhjJGV-4bd_nk8'
    const internalInstance = getCurrentInstance()
    if (!internalInstance.appContext.config.globalProperties.gmapPromise) {
      internalInstance.appContext.config.globalProperties.gmapPromise = new Promise((resolve) => {
        if (mapsKey) {
          window.__onGapiLoaded = () => { resolve(window.gapi) }
          const googleMapScript = document.createElement('SCRIPT')
          googleMapScript.src = 'https://maps.googleapis.com/maps/api/js?key=' + mapsKey + '&callback=__onGapiLoaded'
          googleMapScript.async = googleMapScript.defer = true
          document.head.appendChild(googleMapScript)
        } else {
          resolve(window.gapi)
        }
      })
    }
    return {
      mapsKey
    }
  },
  beforeRouteEnter (to, _from, next) {
    next(vm => {
      vm.initMap()
    })
  },
  beforeRouteUpdate (to, _from, next) {
    this.initMap()
    next()
  },
  data () {
    return {
      map: null
    }
  },
  methods: {
    initMap () {
      this.gmapPromise.then(() => {
        if (this.mapsKey) {
          const gmap = window.google.maps
          const latLng = new gmap.LatLng(56.231666, 12.902515)
          this.map = this.map || new gmap.Map(document.getElementById('map'), { mapTypeId: 'hybrid', zoom: 15, center: latLng })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
#map {
  min-height:50rem;
  padding:1rem;
  margin-bottom:1rem;
  @media (min-width: 1023px) {
      margin:1rem;
  }
}
.directions {
    display:flex;
    @media (max-width: 1022px) {
        flex-direction: column;
        div {
            margin-bottom:1rem;
        }
    }
    div {
        margin-right:1rem;
    }
}
</style>
