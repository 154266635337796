<template>
  <Head>
    <title v-if="event">{{event.Title}} mellan {{formatFromDateString(event)}} och {{formatToDateString(event)}} - Ängelholms Ryttarförening</title>
    <meta v-if="event" name="description" :content="event.Title + ' mellan ' + formatFromDateString(event) + ' och ' + formatToDateString(event) + ' - Ängelholms Ryttarförening'" />
  </Head>
  <img v-if="event&&event.Slide" :src="'/slides/' + event.Slide.ImageUrl" :alt="event.Slide.Title" />
  <main v-if="event">
    <ul class="breadcrumbs">
        <li>
            <router-link to="/">Startsida</router-link>
        </li>
        <li>
            <router-link to="/kalender">Kalender</router-link>
        </li>
        <li>
            {{event.Title}}
        </li>
    </ul>
    <article>
      <h1>{{ event.Title}}</h1>
      <div>
        {{ event.LocationName}}
        <span v-if="event.datesEqual">mellan kl {{ event.FromTime }} och {{ event.ToTime }}</span>
        <span v-else>mellan {{formatFromDateString(event)}} och {{formatToDateString(event)}}</span>
      </div>
      <div v-html="event.Content"></div>
    </article>
  </main>
</template>
<script>
export default {
  data () {
    return {
      event: null
    }
  },
  beforeRouteEnter (to, _from, next) {
    next((vm) => vm.getEvent(to))
  },
  beforeRouteUpdate (to, _from, next) {
    this.getEvent(to)
    next()
  },
  methods: {
    getEvent (to) {
      const eventId = to.params.event.split('-').pop()
      fetch('/api/calendar/' + eventId)
        .then(response => response.json())
        .then(data => { this.event = data })
    },
    formatFromDateString (event) {
      const options = { month: 'long', day: 'numeric' }
      return new Date(event.FromDate).toLocaleDateString('sv-SE', options) + ' kl ' + event.FromTime
    },
    formatToDateString (event) {
      const options = { month: 'long', day: 'numeric' }
      return new Date(event.ToDate).toLocaleDateString('sv-SE', options) + ' kl ' + event.ToTime
    }
  }
}
</script>
