<template>
  <Head>
    <title>Sökresultat för {{searchTerm}} - Ängelholms Ryttarförening</title>
    <meta name="description" :content="'Sökresultat för ' + searchTerm" />
  </Head>
    <main>
        <h1>Sökresultat för '{{searchTerm}}'</h1>
        <template v-if="searchResult">
            <ul v-if="searchResult">
                <li v-if="searchResult.posts&&searchResult.posts.length>0" :class="{active: activeTab==0}">
                    <a href="#tab1" @click.prevent="activeTab=0">Inlägg ({{searchResult.posts.length}})</a>
                </li>
                <li v-if="searchResult.events&&searchResult.events.length>0" :class="{active: activeTab==1}">
                    <a href="#tab2" @click.prevent="activeTab=1">Aktiviteter ({{searchResult.events.length}})</a>
                </li>
                <li v-if="searchResult.pages&&searchResult.pages.length>0" :class="{active: activeTab==2}">
                    <a href="#tab3" @click.prevent="activeTab=2">Sidor ({{searchResult.pages.length}})</a>
                </li>
            </ul>
            <div v-if="searchResult.posts.length>0&&activeTab==0">
                <PostsList :initialNews="searchResult.posts" :totalPostCount="searchResult.totalPostCount" />
            </div>
            <div v-if="searchResult.events.length>0&&activeTab==1">
                <article v-for="event in searchResult.events" :key="event">
                    <h2>{{ event.Title }}</h2>
                    <div>
                        {{ event.LocationName}}
                        <span v-if="event.FromDate.substring(0,10) == event.ToDate.substring(0,10)">{{event.FromDate.substring(0,10)}} mellan kl {{ event.FromTime }} och {{ event.ToTime }}</span>
                        <span v-else>mellan {{formatFromDateString(event)}} och {{formatToDateString(event)}}</span>
                    </div>
                    <div v-html="event.Introduction"></div>
                    <router-link v-if="event.PageUrl" :to="'/' + event.PageUrl">Läs mer</router-link>
                    <router-link v-else-if="event.ContentLength>10" :to="'/kalender/' + event.Url + '-' + event.Id">Läs mer</router-link>
                </article>
            </div>
            <div v-if="searchResult.pages.length>0&&activeTab==2">
                <article v-for="page in searchResult.pages" :key="page">
                    <h2>{{ page.Title }}</h2>
                    <p>{{page.Seo}}</p>
                    <router-link :to="page.Url">Till sidan</router-link>
                </article>
            </div>
        </template>
    </main>
</template>
<script>
import PostsList from './subcomponents/PostsList.vue'
export default {
  components: {
    PostsList
  },
  data () {
    return {
      activeTab: 0,
      searchResult: null,
      searchTerm: null
    }
  },
  beforeRouteEnter (to, _from, next) {
    next((vm) => vm.search(to))
  },
  beforeRouteUpdate (to, _from, next) {
    this.search(to)
    next()
  },
  methods: {
    search (to) {
      if (to.query.sokord) {
        this.searchTerm = to.query.sokord
        fetch('/api/search?sokord=' + to.query.sokord)
          .then(response => response.json())
          .then(data => {
            this.searchResult = data
            if (this.searchResult.posts && this.searchResult.posts.length > 0) {
              this.activeTab = 0
            } else if (this.searchResult.events && this.searchResult.events.length > 0) {
              this.activeTab = 1
            } else if (this.searchResult.pages && this.searchResult.pages.length > 0) {
              this.activeTab = 2
            }
          })
      }
    },
    formatFromDateString (event) {
      const options = { month: 'long', day: 'numeric' }
      return new Date(event.FromDate).toLocaleDateString('sv-SE', options) + ' kl ' + event.FromTime
    },
    formatToDateString (event) {
      const options = { month: 'long', day: 'numeric' }
      return new Date(event.ToDate).toLocaleDateString('sv-SE', options) + ' kl ' + event.ToTime
    }
  }
}
</script>
<style lang="scss" scoped>
ul {
    align-items: center;
    border-bottom: 1px solid #dbdbdb;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: flex-start;
    a {
    align-items: center;
    border-bottom: 1px solid #dbdbdb;
    color: #4a4a4a;
    display: flex;
    justify-content: center;
    margin-bottom: -1px;
    padding: 0.5em 1em;
    vertical-align: top;
    }
    li.active a {
        border-bottom-color: #13ab51;
        color: #13ab51;
    }
    a:hover {
        border-bottom-color: #363636;
        color: #363636;
    }
}
</style>
