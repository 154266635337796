<template>
  <Head>
    <title>Kalender {{formattedMonth}} - Ängelholms Ryttarförening</title>
    <meta name="description" :content="'Kalender för aktiviteter som sker under ' + formattedMonth + ' på Ängelholms Ryttarförening'" />
  </Head>
  <main>
    <ul class="breadcrumbs">
        <li>
            <router-link to="/">Startsida</router-link>
        </li>
        <li>
            <router-link to="/kalender">Kalender</router-link>
        </li>
        <li>
            <router-link :to="'/kalender/' + this.monthString">{{formattedMonth}}</router-link>
        </li>
    </ul>
    <article>
      <h1>Kalender</h1>
      <div class="calendar">
        <Calendar :showFullMonth="true" @month="setMonth" :date="date" />
      </div>
    </article>
  </main>
</template>
<script>
import Calendar from './subcomponents/Calendar.vue'
export default {
  components: {
    Calendar
  },
  data () {
    return {
      date: null,
      monthString: null
    }
  },
  beforeRouteEnter (to, _from, next) {
    next((vm) => vm.setDate(to))
  },
  beforeRouteUpdate (to, _from, next) {
    this.setDate(to)
    next()
  },
  computed: {
    formattedMonth () {
      if (!this.date) return null
      const options = { month: 'long', year: 'numeric' }
      return this.date.toLocaleDateString('sv-SE', options)
    }
  },
  methods: {
    setDate (to) {
      if (to.query.datum) {
        const dateParts = to.query.datum.split('-')
        this.date = new Date(parseInt(dateParts[0]), parseInt(dateParts[1]) - 1)
        this.monthString = to.query.datum
      } else {
        this.setMonth(new Date())
      }
    },
    setMonth (date) {
      const dateString = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0')
      this.$router.push({ path: '/kalender', query: { datum: dateString } })
    }
  }
}
</script>
<style lang="scss">
.calendar {
  @media (min-width: 1023px) {
    display:flex;
  }
  .vc-is-expanded {
    margin-bottom:1rem;
    @media (min-width: 1023px) {
      min-width: 400px;
      margin-right:1rem;
    }
  }
}
</style>
